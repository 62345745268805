import React from "react"
import Layout from "../../components/layout"
import PrimaryButton from "../../components/buttonprimary"
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"

export default function Development() {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>JustPax Collective | Development Services</title>
                <link rel="canonical" href="http://justpaxcollective.com" />
            </Helmet>
            <section class="section--development">
                <section class="wrapper">
                    <div className="section--headline">
                        <h1>Development: Organizational Workshops</h1>
                        <p>We also offer a variety of additional workshops based on organizational needs.</p>
                    </div>
                    <div class="flex-container section--details">
                        <div >
                            <h2>Becoming a Racial Healer</h2>
                            <p>We can’t heal what we don’t see. This foundational six-session training engages in communal learning and equips your team with tools for developing awareness, action, and advocacy. In partnership with organizational leadership, we deliver context-specific training that focuses on how to work toward justice through personal identity and organizational values.
                            </p>
                            <p><em>Optional: </em>This training can be offered from a faith-based perspective.</p>
                            {/* <Link to="/contact-us">Schedule a training</Link> */}
                        </div>
                        <div>
                            <h2>Anti-racism Training: Context, History, and Opportunity</h2>
                            <p>To develop an anti-racist ethos, we need a shared understanding of history to serve as a foundation for future work. This training surveys recent incidents of racial violence against the backdrop of racialized history in America.</p>
                            {/* <Link to="/contact-us">Schedule a training</Link> */}
                        </div>
                        <div>
                            <h2>Racial Healing Circles</h2>
                            <p>We’ve worked with schools, communities, and government departments to promote healing across our racial divides. Healing circles are an effective community development practice that encourage empathy and develop capacity for organizational change.</p>
                            {/* <Link to="/contact-us">Schedule a training</Link> */}
                        </div>
                    </div>
                    <div>
                        <PrimaryButton buttonLink="/contact-us/" buttonText="Schedule a training" />
                    </div>
                </section>
            </section>
        </Layout>
    )
}